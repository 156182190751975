.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: #fff;
  gap: 2rem;
}
.title {
  width: 90%;
  color: #424a4c;

  font-family: "Poppins";
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.cardincontainer1 {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.cardincontainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  border-radius: 22px;
}
.title1 {
  color: #424a4c;
  font-family: "Montserrat";
  font-size: 35px;
  font-style: normal;
  font-weight: bold;
  line-height: 137.5%; /* 48.125px */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.bothbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}
.button1 {
  display: flex;

  padding: 12px 46px 11px 31px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 40px;
  color: #333;

  font-family: Open Sans;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 48.125px */
  width: 31rem;
  justify-content: center;
}
.button1:hover {
  background-color: #f96300;
}
.image {
  width: 15vw;
}
.condition {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  color: #424a4c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 19.25px */
  margin-bottom: 5rem;
}
.conditionsandterm {
  color: #f96300;
}

.containerpagetwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  gap: 4rem;
}
.titleinpagetwo {
  display: flex;
  justify-content: center;
  color: #424a4c;
  font-family: "Poppins";
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 61.875px */
}
.titleinpagetwo1 {
  display: flex;
  justify-content: center;
  color: #424a4c;
  font-family: "Poppins";
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 52.25px */
}
.titleinpagetwo2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  color: #424a4c;
  font-family: "Poppins";
  font-size: 27px;
  font-style: normal;
  font-weight: bold;
  line-height: 137.5%; /* 41.25px */
  text-align: center;
}
.cardincont {
  width: 90%;
  background: white;
  border-radius: 40px;
  padding: 1rem;
}
.widthless {
  width: 70%;
}
.threimages {
  display: flex;
  width: 90%;
  justify-content: space-between;
  gap: 2rem;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 2rem;
}
.accesss {
  color: #333;

  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 48.125px */
}
.accesss:hover {
  color: #f96300;
  cursor: pointer;
}
.accesss11:hover {
  background-color: #f96300;
  cursor: pointer;
}
.accesss11 {
  border-radius: 25px;
  background: #d0d0d0;
  padding: 10px;
  color: #333;
  font-family: "Montserrat";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 34.375px */
  display: flex;
  justify-content: center;
}
.afterdisc {
  color: #c00;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ggrayline {
  width: 100%;
  height: 1rem;
  background: #f96300;
}
.titledivinthird {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.testcontt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}
.key {
  color: #333;
  font-family: "Montserrat";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 48.125px */
}
.benefet {
  color: #333;
  font-family: "Montserrat";
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 45px */
  width: 70%;
}
.contanerinpage3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 2rem;
  gap: 2rem;
}
.contanerinpage3title1 {
  color: #333;
  font-family: "Montserrat";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 48.125px */
}
.contanerinpage3title2 {
  color: #333;
  font-family: "Montserrat";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 41.25px */
}
.divincontanerinpage3 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* width: 0%; */
  width: 80%;
}
.titleinpagetwo2222 {
  display: flex;
  justify-content: flex-start;
  color: #424a4c;
  font-family: "Poppins";
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 52.25px */
}
.titleinpagetwo1111 {
  display: flex;
  justify-content: flex-start;
  color: #424a4c;
  font-family: "Poppins";
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 61.875px */
}
.faqcontainer {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 40px;
  background: #d0d0d0;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 40px;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 1rem;
}
.question {
  color: #333;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;

  line-height: 137.5%; /* 33px */
}
.questioniscoll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.answer {
  color: #14213d;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%; /* 24.75px */
}
.logoimage {
  width: 9rem;
}
.imagecont {
  display: flex;
  justify-content: center;
  width: 30%;
}
.imageone:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
}
.imageone {
  width: 27vw;
}
.navbarcontainer {
  display: flex;
  flex-direction: row;

  align-items: center;
  width: 100%;
  background: #fff;
  box-shadow: 1px 9px 19px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 9px 19px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 9px 19px 0px rgba(0, 0, 0, 0.75);
  flex-wrap: wrap;
}
.searchcont {
  width: 30%;
  display: flex;
  justify-content: center;
}
.navbaritems {
  color: #424a4c;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.FooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #424a4c;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.footertext1 {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footertext2 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%; /* 19.25px */
  cursor: pointer;
}
a {
  text-decoration: none;
}
.qwertyui {
  color: #333;
  text-align: center;
  font-family: "Montserrat";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 137.5%; /* 41.25px */
  margin-bottom: 2rem;
}
.seconddemotwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: 90%;
  height: 100%;
  background-color: #fff;
  margin-top: 2rem;
}
.cardonecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: 400px;
  padding-bottom: 2rem;
  width: 30rem;
  border-radius: 22px;
  border: 2px solid #d0d0d0;
  background-color: #d0d0d0;

  color: #333;
  font-family: "Montserrat";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  height: 18rem;
  line-height: normal;
  padding: 1rem;
}
.containerpagetwo__left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex-wrap: wrap;
  width: 90%;
  margin-top: 4rem;
}
.cardcotainertwo {
  width: 89%;
  border-radius: 22px;
  border: 2px solid #d0d0d0;
  background: #d0d0d0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  padding-left: 3rem;
  margin-bottom: 5rem;
}
.cardcotainertwo1 {
  width: 89%;
  border-radius: 22px;
  border: 2px solid #d0d0d0;
  background: #d0d0d0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  padding-left: 3rem;
}
.oneee {
  color: #333;
  font-family: "Montserrat";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.oneee11 {
  color: #333;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.oneee22 {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 3rem;
  flex-wrap: wrap;
}
.imageone11111111 {
  width: 32rem;
  height: 20rem;
}
.oneee222 {
  display: flex;
  justify-content: flex-start;
  width: 40%;
  height: 5rem;
  border-radius: 15px;
  border: 2px solid #212529;
}
.oneee222three {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  height: 5rem;
  border-radius: 15px;
  border: 2px solid #212529;
  margin-bottom: 3rem;
}
.oneee222three12 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  height: 5rem;
  border-radius: 15px;
  border: 2px solid #212529;
  color: #333;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.oneee222three1212 {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4rem;
}
.oneee22we2 {
  color: #333;
  font-family: "Montserrat";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 1200px) {
  .containerpagetwo__left {
    justify-content: center;
    gap: 4rem;
  }
}

@media (max-width: 1000px) {
  .cardincontainer1 {
    justify-content: center;
  }
  .title1 {
    display: flex;
    justify-content: center;
    width: 80%;
    text-align: center;
  }
  .cardincontainer {
    justify-content: center;
    align-items: center;
  }
  .title {
    display: flex;
    justify-content: center;
  }
  .titleinpagetwo {
    font-size: 30px;
    text-align: center;
  }
  .titleinpagetwo1 {
    font-size: 25px;
  }
  .titleinpagetwo2 {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .threimages {
    justify-content: center;
  }
  .titleinpagetwo2222 {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 920px) {
  .navbarcontainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .navbar {
    width: 100%;
    justify-content: center;
    gap: 2rem;
  }
  .searchcont {
    display: none;
  }
}
@media (max-width: 500px) {
  .title {
    font-size: 50px;
  }
  .imagcardssss {
    width: 4rem;
    height: 3rem;
  }
  .title1 {
    font-size: 25px;
    width: 90%;
  }
  .imageone {
    width: 70vw;
  }
  .navbaritems {
    font-size: 14px;
  }
  .footertext2 {
    font-size: 12px;
    text-align: center;
  }
  .contanerinpage3title1 {
    text-align: center;
    font-size: 20px;
  }
  .contanerinpage3title2 {
    text-align: center;
    font-size: 15px;
  }
  .question {
    font-size: 15px;
  }
  .answer {
    font-size: 12px;
  }
  .footertext1 {
    font-size: 20px;
  }
  .button1 {
    font-size: 20px;
    width: 20rem;
  }
  .qwertyui {
    font-size: 20px;
  }
  .benefet {
    font-size: 20px;
  }
  .key {
    font-size: 20px;
  }
  .imageone11111111 {
    width: 91vw;
    height: 100%;
  }
  .oneee222three12 {
    font-size: 15px;
  }
}
